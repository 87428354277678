import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import HomePage from "../components/PageComponents/HomePage";
import BeginningPage from "../components/PageComponents/BeginningPage";

const Index = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <BeginningPage /> : <HomePage />;
};

export default Index;
