import React from "react";
import Highcharts from "highcharts";
import HighchartsData from "highcharts/modules/data";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import Box from "../../base/Box";

if (typeof Highcharts === "object") {
  HighchartsData(Highcharts);
  HighchartsExporting(Highcharts);
}

const PieChart = ({ chartData }) => {
  const chartOptions = {
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    credits: {
      style: {
        color: "#FFFFFF",
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: true,
        },
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        colors: ["#e51414", "#13812a"],
        cursor: "pointer",
        dataLabels: {
          distance: -30,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        colorByPoint: true,
        data: chartData.data,
        innerSize: "40%",
        name: "Device Count",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    ],
    subtitle: {
      style: {
        color: "#000000",
        fontSize: "20px",
        fontWeight: "700",
      },
      text: `${chartData.total}`,
      verticalAlign: "middle",
    },
    title: {
      style: {
        fontSize: "25px",
        fontWeight: "700",
      },
      text: `${chartData.title}`,
      verticalAlign: "top",
    },
    tooltip: {
      borderWidth: "3px",
      headerFormat: "{point.name}<br>",
      pointFormat:
        "{series.name}:<b> {point.y}</b><br><b>{point.percentage:.1f}%</b>",
      style: {
        fontSize: "14px",
        // padding:"9px",
      },
    },
  };

  return (
    <Box sx={{ mb: 5, width: "500px" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Box>
  );
};

PieChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.shape({})),
};

PieChart.defaultProps = {
  chartData: [],
};

export default PieChart;
