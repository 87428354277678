import React from "react";
import Route from "../../base/Route";
import Section from "../../base/Section";
import PieChart from "../../Charts/PieChart";
import Box from "../../base/Box";

const Beginning = () => {
  /* const data = {
    datasets: [
      {
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
          data: [12, 19, 3, 5],
        label: "Number of Cities",
      },
    ],
    labels: [
      "León, Guanajuato",
      "Monterrey, Nuevo León",
      "San Miguel de Allende, Guanajuato",
      "Ciudad de México",
    ],
  }; */
  const systems = [
    {
      city: "city 1",
      offNodeCount: 6,
      onNodeCount: 4,
      system: "system 1",
      total: 10,
    },
    {
      city: "city 1",
      offNodeCount: 6,
      onNodeCount: 9,
      system: "system 2",
      total: 15,
    },
    {
      city: "city 2",
      offNodeCount: 6,
      onNodeCount: 6,
      system: "system 3",
      total: 12,
    },
    {
      city: "city 3",
      offNodeCount: 6,
      onNodeCount: 0,
      system: "system 4",
      total: 6,
    },
  ];
  return (
    <Route isPrivate layout="admin">
      <Section>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {systems.map((system) => {
            const chartData = {
              data: [
                {
                  name: "On",
                  y: system.onNodeCount,
                },
                {
                  name: "Off",
                  y: system.offNodeCount,
                },
              ],
              title: `${system.city} - ${system.system}`,
              total: system.total,
            };
            return <PieChart chartData={chartData} />;
          })}
        </Box>
      </Section>
    </Route>
  );
};

export default Beginning;
