import React from "react";
import { Button } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import Route from "../../base/Route";
import Box from "../../base/Box";
import Icon from "../../Icon";

const HomePage = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Route>
      <Box
        sx={{
          alignItems: "center",
          bg: "linear-gradient(to top left, #32CD32, #FFFFFF)",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            bg: "white",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "250px",
            justifyContent: "center",
            width: "400px",
          }}
        >
          <Icon svg="wiwi-logo" sx={{ width: "200px" }} />
          <Box
            sx={{
              color: "text.green",
              fontSize: 3,
              fontWeight: "medium",
              mt: 5,
            }}
          >
            Inicia sesión
          </Box>
          <Button mt={4} onClick={() => loginWithRedirect()} variant="primary">
            Continuar
          </Button>
        </Box>
      </Box>
    </Route>
  );
};

export default HomePage;
